import { BrowserAnimationsModule, provideAnimations } from "@angular/platform-browser/animations";
import { provideRouter, withComponentInputBinding, withViewTransitions } from "@angular/router";
import { ApplicationConfig, importProvidersFrom } from "@angular/core";
import { DatePipe } from "@angular/common";
import {
  withInterceptorsFromDi,
  HTTP_INTERCEPTORS,
  provideHttpClient,
  HttpBackend,
  withInterceptors,
} from "@angular/common/http";

import { environment } from "src/environments/environment";

import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from "@angular/material/core";
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from "@angular/material/form-field";

import { MultiTranslateHttpLoader } from "ngx-translate-multi-http-loader";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";

import { SessionStorageService } from "./core/services/session-storage.service";
import { LocalStorageService } from "./core/services/local-storage.service";
import { ObservableService } from "./core/services/observable.service";
import { AuthService } from "./core/services/auth.service";

import { appRoutes as APP_ROUTES } from "./app.routes";

import { InternetInterceptor } from "./core/interceptors/internet.interceptor";
import { LoaderInterceptor } from "./core/interceptors/loader.interceptor";
import { ErrorInterceptor } from "./core/interceptors/error.interceptor";
import { AuthInterceptor } from "./core/interceptors/auth.interceptor";
import { ApiInterceptor } from "./core/interceptors/api.interceptor";
import { MAT_DIALOG_DEFAULT_OPTIONS } from "@angular/material/dialog";
import { interceptors } from "./core/interceptors";

export function createTranslateLoader(http: HttpBackend): MultiTranslateHttpLoader {
  return new MultiTranslateHttpLoader(http, [{ prefix: environment.ASSET, suffix: ".json" }]);
}

const matFormFieldDefaultOptions: MatFormFieldDefaultOptions = {
  appearance: "outline",
  subscriptSizing: "dynamic",
};

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom([BrowserAnimationsModule]),
    importProvidersFrom(MatSnackBarModule),
    provideAnimations(),

    SessionStorageService,
    LocalStorageService,
    ObservableService,
    AuthService,

    provideRouter(APP_ROUTES, withComponentInputBinding(), withViewTransitions()),
    { provide: HTTP_INTERCEPTORS, useClass: InternetInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(
      MatDatepickerModule,
      MatNativeDateModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpBackend],
        },
      }),
    ),

    DatePipe,

    // Global material tokens
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: matFormFieldDefaultOptions },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        autoFocus: false,
      },
    },
    // { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
  ],
};
